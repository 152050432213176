// extracted by mini-css-extract-plugin
export var content = "BlogList-module--content--jSIIe";
export var date = "BlogList-module--date--uuZ8T";
export var description = "BlogList-module--description--uIht9";
export var entry = "BlogList-module--entry--zqCQD";
export var feedIcon = "BlogList-module--feed-icon--fnKQX";
export var feedIconLink = "BlogList-module--feed-icon-link--Chh+L";
export var header = "BlogList-module--header--ZRnq-";
export var link = "BlogList-module--link--rA7OS";
export var selectable = "BlogList-module--selectable--jx0vS";
export var tag = "BlogList-module--tag--h7Xa4";
export var tagContainer = "BlogList-module--tag-container--WFheE";
export var title = "BlogList-module--title--soIDH";
export var unselectable = "BlogList-module--unselectable--mUJiz";